<template>
  <div :style="wrapperStyle" :class="size">
    <div
      :class="{ 'outline-red-500 dark:outline-red-500': errors }"
      class="relative cursor-default overflow-hidden rounded-lg bg-white text-left outline-1 outline-gray-200 dark:outline-cyan-950 focus-within:outline-cyan-800 transition-outline duration-300 sm:text-sm dark:bg-cyan-950"
    >
      <div
        v-if="type === 'email'"
        class="absolute inset-y-0 left-3 flex items-center text-gray-600"
      >
        <Icon icon="bi:person" class="w-6 h-6" color="#124559" />
      </div>

      <div
        v-if="type === 'password'"
        class="absolute inset-y-0 left-3 flex items-center text-gray-600"
      >
        <Icon icon="clarity:lock-line" class="w-6 h-6" color="#124559" />
      </div>

      <input
        :disabled="disabled"
        v-maska
        :data-maska="computedMask"
        :data-maska-tokens="MASK_TOKENS[fieldType] || ''"
        :value="inputValue"
        @input="onInput($event)"
        @blur="handleBlur"
        @keydown="validateNumericInput"
        :type="
          type === 'password' ? (showPassword ? 'text' : 'password') : type
        "
        :name="validationName"
        :class="[
          { 'text-red-500 dark:text-red-500': errors },
          inputPadding.value,
          'w-full h-12 font-montserrat text-base font-normal outline-none pl-3 py-2 pr-10 leading-none focus:ring-0 focus:outline-none rounded-lg border border-gray-400 cursor-pointer',
        ]"
        :placeholder="placeholder"
        :style="{
          paddingLeft:
            type === 'email' || type === 'password' ? '3rem' : '1rem',
        }"
      />

      <div
        v-if="type === 'password'"
        @click="showPassword = !showPassword"
        class="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-600"
      >
        <Icon
          v-if="showPassword"
          icon="ph:eye"
          class="w-6 h-6"
          color="#124559"
        />
        <Icon
          v-else
          icon="ph:eye-closed-bold"
          class="w-6 h-6"
          color="#124559"
        />
      </div>
    </div>
    <span class="text-red-500 mt-2 text-xs h-5 block dark:text-red-500">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue";
import { ref, computed } from "vue";
import { vMaska } from "maska";
import { useField } from "vee-validate";

const emits = defineEmits(["update:modelValue"]);
const showPassword = ref(false);

const MASKS = {
  cpf: "###.###.###-##",
  cnpj: "##.###.###/####-##",
  cellphone: "(##) #####-####",
  phone: "(##) ####-####",
  cep: "####-###",
  hour: "Hh:Mm",
};

const MASK_TOKENS = {
  hour: "H:[0-2]|h:[0-9]|H:[0-2]|h:[0-9]|M:[0-5]|m:[0-9]",
};

const validateNumericInput = (event: KeyboardEvent) => {
  if (
    ["cpf", "cnpj", "cellphone", "phone", "cep", "hour"].includes(fieldType)
  ) {
    if (
      (event.key < "0" || event.key > "9") &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowUp" &&
      event.key !== "ArrowDown" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight" &&
      event.key !== "Shift"
    ) {
      event.preventDefault();
    }
  }
};

const {
  modelValue,
  placeholder,
  validationName,
  fieldType,
  type,
  mask,
  errors,
  size = "w-full",
  wrapperStyle = {},
} = defineProps({
  modelValue: [String, Array, Number],
  placeholder: String,
  validationName: String,
  fieldType: {
    type: String,
    default: "text",
  },
  type: String,
  mask: String,
  errors: String,
  size: String,
  wrapperStyle: Object,
  disabled: {
    type: Boolean,
    default: false,
  },
});

const computedMask = computed(() => {
  if (fieldType in MASKS) {
    return MASKS[fieldType];
  }
  return mask;
});

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
} = useField(validationName, undefined, {
  initialValue: modelValue,
  valueProp: modelValue,
});

const onInput = (event: any) => {
  handleChange(event, true);
  emits("update:modelValue", event.target.value);
};

const inputPadding = computed(() => {
  if (type === "email" || type === "password") {
    return "pl-16";
  }
  return "";
});
</script>
